.lecture-body-appear,
.lecture-body-enter {
  /* transform: scale(0.9); */
  transform: translateX(100vw);
}
.lecture-body-appear.lecture-body-appear-active,
.lecture-body-enter.lecture-body-enter-active {
  transform: translateX(0vw);
  /* transition: translateX 300ms, transform 300ms; */
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms;
}

.lecture-body-enter {
  /* transform: scale(0.9); */
  transform: translateX(100vw);
}

.lecture-body-enter-active {
  transform: translateX(0vw);
  /* transition: translateX 300ms, transform 300ms; */
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms;
}

.lecture-body-exit {
  transform: translateX(0vw);
}
.lecture-body-exit-active {
  /* opacity: 0; */
  /* transform: scale(0.9); */
  transform: translateX(100vw);
  /* transition: translateX 300ms, transform 300ms; */
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms;
}
