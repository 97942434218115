/* DB Heavent */
/* iframe {
  display: none;
} */

@font-face {
  font-family: 'DB Heavent';
  src: url(./fonts/DB-Heavent-Med-v3.2.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'DB Heavent';
  src: url(./fonts/DB-Heavent-Med-v3.2.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'DB Heavent';
  src: url(./fonts/DB-Heavent-v3.2.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'DB Heavent';
  src: url(./fonts/DB-Heavent-Li-v3.2.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'DB Heavent';
  src: url(./fonts/DB-Heavent-Thin-v3.2.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'DB Heavent';
  src: url(./fonts/DB-Heavent-UlLi-v3.2.ttf) format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'DB Heavent';
  src: url(./fonts/DB-Heavent-UlLi-v3.2.ttf) format('truetype');
  font-style: normal;
  font-weight: 100;
}

/* SukhumvitSet-Light
SukhumvitSet-Bold
SukhumvitSet-Medium
SukhumvitSet-SemiBold
SukhumvitSet-Text
SukhumvitSet-Thin */
@font-face {
  font-family: 'Sukhumvit Set';
  src: url(./fonts/SukhumvitSet-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'Sukhumvit Set';
  src: url(./fonts/SukhumvitSet-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Sukhumvit Set';
  src: url(./fonts/SukhumvitSet-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
  /* position: relative; */
  background-color: #fafafa !important;
  height: 100%;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-datepicker-wrapper {
  width: 100%;
}

#zmmtg-root {
  display: none;
  top: 68px;
  z-index: 500;
}

.rccs {
  margin: 0 !important;
}

.tooltip-container {
  z-index: 10000000 !important;
}

/* =================== intro ====================== */
.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
}

.introjs-tooltip-header {
  padding: 16px 16px 0 24px;
}

.introjs-tooltip-title {
  font-weight: 600;
  color: #1f1f1f;
  font-family: 'Sarabun';
  font-style: normal;
}

.introjs-skipbutton {
  padding: 0;
  color: #a6a6a6;
  font-size: 26px;
  font-weight: 200;
  line-height: 0.7;
}

.introjs-tooltiptext {
  padding: 15px 24px 10px 24px;
}

.introjs-helperNumberLayer {
  color: #6f6f6f;
  font-family: 'Sarabun';
  font-style: normal;
  font-size: 16px;
}

.introjs-tooltipbuttons {
  border-top: 1px solid #cdcdcd;
  padding: 10px 24px;
}

.introjs-button {
  background-color: #5a69fe;
  color: #fff;
  font-family: 'Sarabun';
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  border: none;
  text-shadow: none;
}

.introjs-button:hover {
  background-color: #5346f9;
  color: #fff;
}

.introjs-button:active {
  background-color: #5346f9;
  color: #fff;
}

.introjs-button:focus {
  background-color: #5346f9;
  color: #fff;
  border: none;
  box-shadow: none;
}

.introjs-tooltip {
  border-radius: 8px;
  min-width: 420px;
  max-width: 420px;
}

.left-space {
  left: 30% !important;
}

.left-space > .introjs-arrow.top {
  left: 50% !important;
}

.center-arrow {
  left: 0% !important;
}

.center-arrow > .introjs-arrow.top {
  left: 50% !important;
}

.center-arrow > .introjs-arrow.bottom {
  left: 50% !important;
}

.change-role {
  top: 180px !important;
}

.course-mobile {
  margin-top: 40px !important;
}

.hide-intro {
  display: none;
}

.profile-popup {
  right: 188px !important;
  top: 317px !important;
}

.profile-popup > .introjs-arrow.right {
  top: 138px !important;
}

.profile-popup-highlight {
  border-radius: 50% !important;
}

.at-create-button {
  left: -150px !important;
}

.at-create-button > .introjs-arrow.bottom {
  left: 50% !important;
}

.at-apply-button {
  left: -163px !important;
}

.at-apply-button > .introjs-arrow.bottom {
  left: 50% !important;
}

.at-certificate-button {
  left: -120px !important;
}

.at-certificate-button > .introjs-arrow.bottom {
  left: 50% !important;
}

.left-at-sidebar > .introjs-arrow.top {
  left: 70px !important;
}

.at-add-button > .introjs-arrow.top-right {
  right: 55px !important;
}

.at-report-button > .introjs-arrow.top-right {
  right: 35px !important;
}

@media (max-height: 700px) {
  .course-mobile {
    margin-top: 100px !important;
  }
}

@media (max-width: 1280px) {
  .left-at-sidebar {
    left: 200px !important;
  }

  .highlight-at-sidebar {
    left: 18px !important;
  }
}

@media (max-width: 1024px) {
  .profile-popup {
    top: 363px !important;
  }
}

@media (max-width: 768px) {
  .left-space {
    left: 20px !important;
  }

  .change-role {
    right: 200px !important;
    top: 52px !important;
  }

  .change-role-highlight {
    border-radius: 50% !important;
  }

  .profile-popup {
    right: 195px !important;
  }
}

@media (max-width: 651px) {
  .change-role {
    right: 0px !important;
    top: 120px !important;
  }

  .change-role > .introjs-arrow.top-right {
    right: 90px !important;
  }

  .profile-popup {
    right: 0px !important;
    top: 255px !important;
  }

  .profile-popup > .introjs-arrow.right {
    display: none !important;
  }
}

@media (max-width: 570px) {
  .at-add-button {
    min-width: 300px;
    max-width: 300px;
  }
}

@media (max-width: 550px) {
  .at-report-button {
    min-width: 300px;
    max-width: 300px;
  }
}

@media (max-width: 490px) {
  .change-role > .introjs-arrow.top-middle {
    left: 315px !important;
  }

  .profile-popup > .introjs-arrow.top-middle {
    display: none !important;
  }
}

@media (max-width: 450px) {
  .introjs-tooltip {
    min-width: 300px;
    max-width: 300px;
  }

  .at-add-button {
    min-width: 260px;
    max-width: 260px;
    left: -152px !important;
  }

  .at-add-button > .introjs-arrow.top-middle {
    left: 84% !important;
  }

  .profile-popup {
    top: 230px !important;
  }
}

@media (max-width: 425px) {
  .at-report-button {
    min-width: 260px;
    max-width: 260px;
    left: -175px !important;
  }

  .at-report-button > .introjs-arrow.top-middle {
    left: 84% !important;
  }
}

@media (max-width: 368px) {
  .change-role > .introjs-arrow.top-middle {
    left: 50% !important;
  }
}

@media (max-width: 320px) {
  .introjs-tooltip {
    min-width: 260px;
    max-width: 260px;
  }
}

/* ================================================ */
