::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 10px;

  /* border: 1px solid transparent; */
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
  width: 8px;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 5px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 5px;
}
